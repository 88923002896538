import { navigate } from "gatsby"
import * as React from "react"
import SEO from "../components/seo"
import Button from "../components/button"
import { useContext } from "react"
import { BackgroundContext } from "../components/layout"

const IndexPage = () => {
  const context = useContext(BackgroundContext)

  console.log(context)

  return (
    <>
      <SEO title="Home" />

      <div className="index">
        <h2>Hi there!</h2>
        <p>
          My name is Jens, and I'm a creative software developer based in
          Cologne.
        </p>

        <div className="buttons">
          <Button
            onClick={() => {
              navigate("/about")
              context.setBackground({ wireframe: false })
            }}
          >
            /about
          </Button>
          <Button
            onClick={() => {
              navigate("/projects")
              context.setBackground({ wireframe: false })
            }}
          >
            /projects
          </Button>
          <Button
            onClick={() => {
              navigate("/work")
              context.setBackground({ wireframe: false })
            }}
          >
            /work
          </Button>
        </div>
      </div>
    </>
  )
}

export default IndexPage
